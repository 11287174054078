
import DateFormat from "@/libs/DateFormat";

export default class Quotation {
  constructor(data = {}) {
    return {
      id: data.id || "",
      quotationNo:data.quotationNo || "",
      username:data.username || "",
      email:data.email || "",
      phoneno:data.phoneno || "",
      contactname:data.contactname || "",
      contactphone:data.contactphone || "",
      contactemail:data.contactemail || "",
      status: data.status || "",
      mobileNo:data.mobileNo || "",
      shippingmethod: data.shippingmethod || "",
      pamentmethod: data.pamentmethod || "",
      remark: data.remark || "",
      offeramt: data.offeramt || "",
      reciveddate: DateFormat.formatDateTime(data.reciveddate) || "",
    };
  }
}
