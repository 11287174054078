<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.userid"
                sectionClass="field"
                id="userid"
                label="User ID"
                class="disable-text-field"
                placeholder="User Id"
                disabled
                v-if="isEditMode"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.username"
                :validation="v$.vmodel.username"
                @keydown.space.prevent
                sectionClass="field"
                id="username"
                label="User Name"
                placeholder="User Name"
                maxlength="50"
                v-if="!isEditMode"
              />
              <InputTextRequire
                v-model="vmodel.username"
                :validation="v$.vmodel.username"
                sectionClass="field"
                id="username"
                label="User Name"
                placeholder="User Name"
                maxlength="50"
                v-if="isEditMode"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.email"
                :validation="v$.vmodel.email"
                @keydown.space.prevent
                sectionClass="field"
                id="email"
                label="Email"
                placeholder="Email"
                maxlength="50"
                v-if="!isEditMode"
              />
              <InputTextRequire
                v-model="vmodel.email"
                :validation="v$.vmodel.email"
                @keydown.space.prevent
                sectionClass="field"
                id="email"
                label="Email"
                placeholder="Email"
                maxlength="50"
                v-if="isEditMode"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.phoneno"
                :validation="v$.vmodel.phoneno"
                @keydown.space.prevent
                sectionClass="field"
                id="phone"
                label="Phone"
                placeholder="Phone"
                maxlength="8"
                v-if="!isEditMode"
              />
              <InputTextRequire
                v-model="vmodel.phoneno"
                :validation="v$.vmodel.phoneno"
                @keydown.space.prevent
                sectionClass="field"
                id="phone"
                label="Phone"
                placeholder="Phone"
                maxlength="8"
                v-if="isEditMode"
              />
            </div>
          </div>

          <div class="grid">
            <div class="col-4 field" v-if="!isEditMode">
              <label for="password" class="form-label float-left">
                Password</label
              >
              <InputText
                v-model="vmodel.password"
                @keydown.space.prevent
                sectionClass="field"
                class="pass"
                type="password"
                id="password"
                placeholder="Password"
                maxlength="20"
                minlength="8"
                :class="{
                  'p-invalid': v$.vmodel.password.$error,
                }"
                aria-describedby="password-help"
                :IsPassword="true"
              />

              <span
                v-if="
                  v$.vmodel.password.$error &&
                  v$.vmodel.password.required.$invalid
                "
                id="password-help"
                class="p-error text-left mt-2"
              >
                {{
                  v$.vmodel.password.required.$message.replace(
                    "Value",
                    "Password"
                  )
                }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
                "
                id="password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.password.min.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
                "
                id="password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.password.max.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.password.$error &&
                  v$.vmodel.password.isValidPassword
                "
                
                id="password-help"
                class="p-error mt-2"
              >
                Password must include at least one uppercase letter, one
                lowercase letter, one numeric digit, and one special character.
              </span>
            </div>
          </div>
          <div class="grid" :class="{margin: v$.vmodel.password.isValidPassword && v$.vmodel.password.$error , margin2: v$.vmodel.password.required && v$.vmodel.password.minLength , margin3: !v$.vmodel.password.required }">
            <div class="col-4 field" v-if="!isEditMode">
              <label for="confirmpassword" class="form-label float-left">
                Confirm Password</label
              >
              <InputText
                v-model="vmodel.confirmpassword"
                @keydown.space.prevent
                type="password"
                sectionClass="field"
                class="pass"
                id="confirmpassword"
                placeholder="Confirm Password"
                maxlength="20"
                minlength="8"
                :class="{
                  'p-invalid': v$.vmodel.confirmpassword.$error,
                }"
                aria-describedby="confirmpassword-help"
              />
              <span
                v-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.required.$invalid
                "
                id="confirmpassword-help"
                class="p-error text-left mt-2"
              >
                {{
                  v$.vmodel.confirmpassword.required.$message.replace(
                    "Value",
                    "Confirm Password"
                  )
                }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.min.$invalid
                "
                id="confirmpassword-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.confirmpassword.min.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.max.$invalid
                "
                id="confirmpassword-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.confirmpassword.max.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.sameAs.$invalid
                "
                id="confirmpassword-help"
                class="p-error text-left mt-2"
              >
                Password and Confirm Password must be match
              </span>
            </div>
          </div>

          <div class="mt-4">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createUser"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createUser"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";
import getPassword from "@/libs/getPassword";
import { isValidPassword } from "@/services/validator.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Create User",
      profileimage: "default.png",
      active: false,
      vmodel: {
        userid: "",
        username: "",
        email: "",
        phoneno: "",
        password: "",
        confirmpassword: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit User";
      this.isEditMode = true;
      this.getUserById();
    }
    // else {
    //       this.vmodel.userid = Math.floor(
    //         10000000 + Math.random() * 90000000
    //       ).toString();
    //     }
  },
  validations() {
    return {
      vmodel: {
        username: { required, max: maxLength(50) },
        phoneno: { required, min: minLength(8), max: maxLength(8), numeric },
        email: { required, email, max: maxLength(50) },
        password: {
          required,
          min: minLength(8),
          max: maxLength(20),
          isValidPassword,
        },
        confirmpassword: {
          required,
          min: minLength(8),
          max: maxLength(20),
          sameAs: sameAs(this.vmodel.password),
        },
      },
    };
  },
  methods: {
    validatePasswordFormat() {
      const password = this.vmodel.password;
      this.vmodel.passwordFormatInvalid = !isValidPassword(password);
    },
    // onlyNumber($event) {
    //   let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    //   if (keyCode < 48 || keyCode > 57) {
    //     // 46 is dot
    //     $event.preventDefault();
    //   }
    // },
    // mouseOver() {
    //   if (this.profileimage !== "default.png") {
    //     this.active = true;
    //   }
    // },
    // mouseLeave() {
    //   if (this.profileimage !== "default.png") {
    //     this.active = false;
    //   }
    // },
    // removeImage() {
    //   this.vmodel.profileimage = "default.png";
    //   this.profileimage = "default.png";
    //   this.active = false;
    // },

    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          username: this.vmodel.username,
          email: this.vmodel.email,
          phoneno: this.vmodel.phoneno,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/member", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          let password = getPassword.generatePassword(this.vmodel.password);
          let encodedPassword = encodeURIComponent(password);
          let payload = {
            // userid: this.vmodel.userid,
            username: this.vmodel.username,
            email: this.vmodel.email,
            phoneno: this.vmodel.phoneno,
            password: encodedPassword,
          };
          APIService.post("/member", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getUserById() {
      APIService.getById("/member", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.email;
            this.vmodel.phoneno = response.data.data.phoneno;
            let password = decodeURIComponent(response.data.data.password);
            let decryptedPassword = getPassword.getPassword(password);
            this.vmodel.password = decryptedPassword;
            this.vmodel.confirmpassword = decryptedPassword;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
.p-disabled,
.p-component:disabled {
  opacity: 0 !important;
  color: #1d2129 !important;
}
.margin{
  margin-top: 10px !important;
}
.margin2{
  margin-top: 0.5rem !important;
}
.margin3{
  margin-top: 25px !important;
}
</style>
