<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.paymentmethod"
                :validation="v$.vmodel.paymentmethod"
                sectionClass="field"
                id="PaymentMethod"
                label="Payment Method"
                placeholder="Payment Method"
                maxlength="50"
                @keydown="AlphabetsWithSpaces"
              
              />
            </div>
          </div>
          <div class="mt-4">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createUser"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createUser"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Create User",
      active: false,
      vmodel: {
        paymentmethod: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getCategoryById();
    }
  },
  validations() {
    return {
      vmodel: {
        paymentmethod: { required, max: maxLength(50) },
      },
    };
  },
  methods: {
AlphabetsWithSpaces(e) {
  const charCode = e.keyCode || e.which;
  const char = String.fromCharCode(charCode);

  // Allow alphabets, spaces, and backspace
  if (/^[A-Za-z\s]+$/.test(char) || charCode === 8) {
    return true;
  } else {
    e.preventDefault();
  }
},
    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          paymentmethod: this.vmodel.paymentmethod,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/payment", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PaymentMethod" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/payment", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PaymentMethod" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getCategoryById() {
      // this.image = "";
      APIService.getById("/payment", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.paymentmethod = response.data.data.paymentmethod;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "PaymentMethod" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/uploadimg.css";
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: #e5e6eb;
    color: #c9cdd4;
  }
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
</style>
