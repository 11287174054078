// import DateFormat from "@/libs/DateFormat";
export default class Shipping {
    constructor(data = {}) {
      return {
        id: data.id || "",
  
        shippingmethod: data.shippingmethod || "",
      
        //   createddate: DateFormat.formatDate(data.createddate) || "",
      };
    }
  }
  