/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import store from "./store";


import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import DropDownRequire from "@/components/form-control/drop-down.vue";
import TextAreaRequire from "@/components/form-control/text-area.vue";

import InputTextRequire from "@/components/form-control/input-text.vue";
import InputNumberRequire from "@/components/form-control/input-number.vue";
import QuotationPopup from "@/components/modal-popup/quotation-popup.vue";
import ConfirmPopup from "@/components/modal-popup/confirm-popup.vue";


import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "@/assets/css/global.css";
const app = createApp(App);
app.use(ToastService);
// app.use(ConfirmationService);
app.use(PrimeVue);
app.use(store);
// app.use(moment);

app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("InputNumber", InputNumber);
app.component("PrimeButton", Button);
app.component("FileUpload", FileUpload);

app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("PrimeCard", Card);
app.component("Dropdown", Dropdown);
app.component("PrimeToast", Toast);
app.component("ToastService", ToastService);
app.component("DropDownRequire", DropDownRequire);
app.component("TextAreaRequire", TextAreaRequire);

app.component("InputTextRequire", InputTextRequire);
app.component("InputNumberRequire", InputNumberRequire);
app.component("QuotationPopup", QuotationPopup);
app.component("ConfirmPopup", ConfirmPopup);

app.use(router).mount("#app");
