import Payment from "@/models/payment";

export default class PaymentFactory {
  static createFromJson(json) {
    return new Payment(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(PaymentFactory.createFromJson(item));
    });

    return jsonData;
  }
}
