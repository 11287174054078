<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="grid main-container justify-content-center align-items-center">
    <!-- <div class="login-logo">
      <img src="../assets/icons/login-logo.png" height="100" width="484" />
    </div> -->
    <div class="grid form-container mt-0">
        <div class="login-logo">
         <img src="../assets/logo.jpg"/>
      </div>
      <div class="login-form-title mt-6">Login</div>
      <div class="form-inputs mt-0">
        <div class="field text-left">
          <!-- <div class="col-6 "> -->
          <div >
            <label for="email" class="form-label">Email</label>
            <!-- <i class="email-icon" id="search" /> -->

            <InputText
              id="email"
              type="email"
              icon="pi pi-check"
              placeholder="Email"
              class="w-full login-input mt-2"
              v-model="vmodel.email"
              maxLength="50"
              :class="{
                'p-invalid': v$.vmodel.email.$error,
              }"
              aria-describedby="email-help"
            />
            <!-- </div> -->
          </div>
          <span
            v-if="v$.vmodel.email.$error && v$.vmodel.email.required.$invalid"
            id="email-help"
            class="p-error text-left mt-2"
          >
            {{ v$.vmodel.email.required.$message.replace("Value", "Email") }}
          </span>
          <span
            v-else-if="v$.vmodel.email.$error && v$.vmodel.email.email.$invalid"
            id="email-help"
            class="p-error text-left mt-2"
          >
            {{ v$.vmodel.email.email.$message }}
          </span>
          <span
            v-else-if="v$.vmodel.email.$error && v$.vmodel.email.max.$invalid"
            id="email-help"
            class="p-error text-left mt-2"
          >
            {{ v$.vmodel.email.max.$message }}
          </span>
        </div>
        <div class="field mt-1">
          <label for="password" class="form-label float-left">Password</label>
          <!-- <div class="mt-2"> -->
          <span >
            <div >
            </div>
        

            <InputText
              id="password"
              placeholder="Password"
              :type="passwordInputType"
              class="w-full login-input"
              v-model="vmodel.password"
              :class="{
                'p-invalid': v$.vmodel.password.$error,
              }"
              aria-describedby="password-help"
               maxlength="12"
            />
          </span>
          <!-- </div> -->
          <span
            v-if="
              v$.vmodel.password.$error && v$.vmodel.password.required.$invalid
            "
            id="password-help"
            class="p-error mt-2"
          >
            {{
              v$.vmodel.password.required.$message.replace("Value", "Password")
            }}
          </span>
          <span
            v-else-if="
              v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
            "
            id="password-help"
            class="p-error mt-2"
          >
            {{ v$.vmodel.password.min.$message }}
          </span>
          <span
            v-else-if="
              v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
            "
            id="password-help"
            class="p-error mt-2"
          >
            {{ v$.vmodel.password.max.$message }}
          </span>
        </div>
        <div class="field mt-5 mb-2">
          <PrimeButton
            label="Login"
            class="btn-login"
            @click="login"
          ></PrimeButton>
        </div>
            </div>
    </div>
    <PrimeToast />
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api-service.js";
import getPassword from "@/libs/getPassword";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showPassword: false,
      vmodel: {
        email: null,
        password: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        email: { required, email, max: maxLength(50) },
        password: { required, min: minLength(8), max: maxLength(20) },
      },
    };
  },
  computed: {
    passwordInputType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
  },
  methods: {
    // gotoForgotPassword() {
    //   this.$router.push({ name: "ForgotPassword" });
    // },
    async login() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let password = getPassword.generatePassword(this.vmodel.password);
        let encodedPassword = encodeURIComponent(password);
        let payload = {
          email: this.vmodel.email,
          password: encodedPassword,
        };
        this.submitted = true;
        APIService.post("/admin/login", payload)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.$store.commit("setUserInfo", response.data.data);
              this.$store.commit("setUserToken", response.data.token);
              this.$store.dispatch("login");
              this.$router.push({ name: "MemberManagement" });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/login.css";
::v-deep(.p-input-icon-left > .p-inputtext) {
  padding-left: 3rem !important;
}
::v-deep(.p-input-icon-right > .p-inputtext) {
  padding-left: 3rem !important;
}
 ::v-deep(.p-input-icon-right>i){
    position: absolute;
    top: 60%;
    /* margin-top: -0.5rem; */
}
.p-inputtext.login-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 442px !important;
  height: 48px;

  background: #f2f3f5;
  border-radius: 2px;
}
.p-inputtext.login-input.p-invalid {
  border: 1px solid #dc3545 !important;
}
.p-inputtext.login-input:hover {
  border: none !important;
}
.p-inputtext.login-input.p-invalid:hover {
  border: 1px solid #dc3545 !important;
}


.form-label {
  width: 75px;
  height: 24px;

  font-family: NunitoSans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #262626 !important;
}
.p-input-icon-right .p-input-icon-left{
  display: block;
}
</style>
