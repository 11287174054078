<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/logo.jpg" /></span>
    </div>

    <div class="menu mt-0">
      <router-link
        :to="{ name: 'MemberManagement' }"
        id="Member"
        :class="
          $store.state.activeRoute === 'MemberManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'MemberManagement'
              ? 'user-active-icon selected'
              : 'user-icon'
          "
        ></i>
        <span class="menu-text">Member Management</span>
      </router-link>

      <router-link
        :to="{ name: 'NewsManagement' }"
        id="news"
        :class="
          $store.state.activeRoute === 'NewsManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'NewsManagement'
              ? 'news-active-icon selected'
              : 'news-icon'
          "
        ></i>
        <span class="menu-text">News Management</span>
      </router-link>
      <router-link
        :to="{ name: 'ProductManagement' }"
        id="product"
        :class="
          $store.state.activeRoute === 'ProductManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'ProductManagement'
              ? 'product-active-icon selected'
              : 'product-icon'
          "
        ></i>
        <span class="menu-text">Product Management</span>
      </router-link>
      <router-link
        :to="{ name: 'QuotationRecord' }"
        id="product"
        :class="
          $store.state.activeRoute === 'QuotationRecord'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'QuotationRecord'
              ? 'quotation-active-icon  selected'
              : 'quotation-icon '
          "
        ></i>
        <span class="menu-text">Quotation Record</span>
      </router-link>
      <div
        class="menu-item cursor-pointer"
        @click="visibleDropdown = !visibleDropdown"
      >
        <i class="pushpin-icon"></i>
        <span class="menu-text">Dropdown List</span>
        <i
          :class="{
            'chevron-down': !visibleDropdown,
            'chevron-up': visibleDropdown,
          }"
        ></i>
      </div>
      <router-link
        :to="{ name: 'PaymentMethod' }"
        id="contact"
        v-if="visibleDropdown"
        :class="
          $store.state.activeRoute === 'PaymentMethod'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Payment Method</span>
      </router-link>
      <router-link
        :to="{ name: 'ShippingMethod' }"
        id="contact"
        v-if="visibleDropdown"
        :class="
          $store.state.activeRoute === 'ShippingMethod'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Shipping Method</span>
      </router-link>

      <div
        class="menu-item cursor-pointer"
        @click="visibleDDMenu = !visibleDDMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i
          :class="{
            'chevron-down': !visibleDDMenu,
            'chevron-up': visibleDDMenu,
          }"
        ></i>
      </div>

      <router-link
        :to="{ name: 'AboutUs' }"
        id="about"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'AboutUs'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">About Us</span>
      </router-link>

      <router-link
        :to="{ name: 'ContactUs' }"
        id="contact"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'ContactUs'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Contact Us</span>
      </router-link>
      <router-link
        :to="{ name: 'PrivacyPolicy' }"
        id="privacy"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Privacy Policy</span>
      </router-link>
      <router-link
        :to="{ name: 'TermsAndConditions' }"
        id="terms"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Terms & Conditions</span>
      </router-link>
      <router-link
        :to="{ name: 'ProfileSetting' }"
        id="profile"
        :class="
          $store.state.activeRoute === 'ProfileSetting'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'ProfileSetting'
              ? 'profile-active-icon selected'
              : 'profile-icon'
          "
        ></i>
        <span class="menu-text">Profile Setting</span>
      </router-link>
      <router-link
        :to="{ name: 'Login' }"
        id="logout"
        @click="logout()"
        class="menu-item"
      >
        <i class="logout-icon"></i>
        <span class="menu-text">Logout</span>
      </router-link>
      <!-- <div>
        <img src="../../assets/icons/logout.png" />
        <span class="menu-text" @click="logout()">Logout</span>
        </div> -->
    </div>
    <span class="footer">Hok Shing Group Food Limited</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visibleDDMenu: false,
      visibleDropdown: false,
    };
  },
  methods: {
    // hide() {
    //   this.visibleDDMenu = false;
    // },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style scoped>
@import "@/assets/scss/sidebar.scss";
::-webkit-scrollbar {
  width: 5px;
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  gap: 20px;
  width: 300px;
  order: 0;
  flex-grow: 0;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  gap: 20px;
  width: 300px;
  order: 0;
  flex-grow: 0;
  color: #000;

  /* 16/EN-Regular */
  font-size: 16px;
  font-family: NunitoSans;
  line-height: 24px;
}
/* .dropdown-list-padding {
  padding-left: 57px;
}

.margin {
  margin-left: 17px !important;
  margin-top: 9px;
} */
</style>

