import Shipping from "@/models/shipping";

export default class ShippingFactory {
  static createFromJson(json) {
    return new Shipping(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(ShippingFactory.createFromJson(item));
    });

    return jsonData;
  }
}
