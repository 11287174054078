<template>
  <Dialog v-model:visible="display" modal header="Quotation" @hide="closePopup">
    <div>
      <table class="table-data">
        <tr>
          <th><span class="table-header">Quotation No.</span></th>
          <td>
            <span class="table-data-value">{{ Record.quotationNo }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">User ID</span></th>
          <td>
            <span class="table-data-value">{{ Record.userid }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Username</span></th>
          <td>
            <span class="table-data-value">{{ Record.username }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Phone</span></th>
          <td>
            <span class="table-data-value">{{ Record.phoneno }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Email</span></th>
          <td>
            <span class="table-data-value">{{ Record.email }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Contact Name</span></th>
          <td>
            <span class="table-data-value">{{ Record.contactname }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Contact Email</span></th>
          <td>
            <span class="table-data-value">{{ Record.contactemail }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Contact Phone</span></th>
          <td>
            <span class="table-data-value">{{ Record.contactphone }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Product(s)</span></th>
          <td>
            <div v-for="(item, index) in Record.item" :key="index">
              <span class="table-data-value">{{ item.productname }} </span>
              <span class="table-data-value"> Quantity: {{ item.qty }} </span>
            </div>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Payment method</span></th>
          <td>
            <span class="table-data-value">{{ Record.paymentmethod }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Shipping Method</span></th>
          <td>
            <span class="table-data-value">{{ Record.shippingmethod }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Received Date</span></th>
          <td>
            <span class="table-data-value">{{ Record.receiveddate }} </span>
          </td>
        </tr>
        <tr></tr>
        <tr>
          <th><span class="table-header">Remarks</span></th>
          <td>
            <span class="table-data-value">{{ Record.remark }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Offer amount</span></th>
          <td>
            <InputNumberRequire
              :validation="v$.Record.offeramt"
              v-model="Record.offeramt"
              id="offeramt"
              class="offer"
              :disabled="isOfferAmountDisabled"
              MaxValue="99999999"
            />
          </td>
        </tr>
      </table>

      <div class="mt-3">
        <PrimeButton
          v-if="
            Record.offeramt !== null &&
            Record.offeramt !== '' &&
            Record.offeramt !== undefined &&
            !isOfferAmountDisabled
          "
          label="Send"
          class="changestatus-button"
          @click="Edit()"
        ></PrimeButton>
      </div>

      <!-- <PrimeButton
          label="Delete"
          class="remove-btn"
          @click="deleteComment(id)"
        ></PrimeButton> -->
    </div>
  </Dialog>
</template>

<script>
import APIService from "@/services/api-service.js";
import Dialog from "primevue/dialog";
import DateFormat from "@/libs/DateFormat";
import { useVuelidate } from "@vuelidate/core";
import { maxValue } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    Dialog,
  },
  data() {
    return {
      status: [],
      display: false,
      Record: {
        status: "Waiting",
        offeramt: null,
      },
      isOfferAmountDisabled: false,
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    recordId: {
      type: String,
      required: true,
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
        }
      },
      deep: true,
      immediate: true,
    },
    recordId: {
      handler(newValue) {
        if (newValue !== "") {
          this.RecordView();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {
      Record: {
        offeramt: { maxValue: maxValue(99999999)},
      },
    };
  },
  created() {
    this.formatDate = DateFormat.formatDateTime;
  },

  methods: {
    // async disableDropdownOptions() {
    //   console.log(this.Record.status, "this.Record.status");
    //   const statusSequence = this.status.filter(
    //     (x) => x.value.toLowerCase() === this.Record.status.toLowerCase()
    //   );
    //   if (statusSequence && statusSequence.length > 0) {
    //     const data = this.status.filter(
    //       (x) => x.sequence >= statusSequence[0].sequence
    //     );
    //     this.status = data;
    //   }
    // },
   async Edit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
 let payload = {
        offeramt: this.Record.offeramt,
      };
      APIService.put("/quotation", payload, this.recordId).then((response) => {
        if (response && response.data && response.data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: response.data.message,
            life: 3000,
          });
          this.deleteSuccess();
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
      });
      }
   },
    async RecordView() {
      
      await APIService.get(`/quotation/${this.recordId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.Record.username = response.data.data[0].username;
            this.Record.quotationNo = response.data.data[0].quotationNo;
            this.Record.userid = response.data.data[0].userid;
            this.Record.phoneno = response.data.data[0].phoneno;
            this.Record.email = response.data.data[0].email;
            this.Record.shippingmethod = response.data.data[0].shippingmethod;
            this.Record.paymentmethod = response.data.data[0].paymentmethod;
            this.Record.contactname = response.data.data[0].contactname;
            this.Record.contactphone = response.data.data[0].contactphone;
            this.Record.contactemail = response.data.data[0].contactemail;
            this.Record.remark = response.data.data[0].remark;
            this.Record.item = response.data.data[0].item;
            this.Record.status = response.data.data[0].status;
            this.Record.offeramt = response.data.data[0].offeramt;

            // Disable the offer amount if the status is not 'Waiting'
            this.isOfferAmountDisabled = this.Record.status !== "Waiting";

            this.Record.receiveddate = DateFormat.formatDateTime(
              response.data.data[0].receiveddate
            );
            console.log(this.Record.receiveddate, "receiveddate");
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            this.$emit("displayNo");
            this.display = false;
          }
        })
        .finally(() => {
          // this.disableDropdownOptions();
        });
    },
    // Edit() {
     
    // },
    openPopup() {
      this.display = true;
      // this.status = [
      //   { label: "In Progress", value: "In progress", sequence: 1 },
      //   { label: "Confirmed", value: "Confirmed", sequence: 2 },
      //   { label: "Cancelled", value: "Cancelled", sequence: 3 },
      //   { label: "Delivered", value: "Delivered", sequence: 4 },
      // ];
    },
    closePopup() {
      this.status = [];
      this.display = false;
      this.$emit("displayNo");
    },
    deleteSuccess() {
      this.status = [];
      this.display = false;
      this.$emit("deleteSuccess");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-dialog) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;

  position: absolute;
  width: 1042px;
  height: 657px;
  left: calc(50% - 1042px / 2);
  top: calc(50% - 657px / 2 - 0.5px);
}
.changestatus-button {
  color: #ffffff !important;
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  background: #901a1c !important;
  border: 1px solid #901a1c !important;
  height: 34px !important;
  padding: 5px 16px 5px 16px !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}
::v-deep(.offer) {
  width: 220px;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #dc3545;
}
</style>
