import Quotation from "@/models/quotation";

export default class QuotationFactory {
  static createFromJson(json) {
    return new Quotation(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(QuotationFactory.createFromJson(item));
    });

    return jsonData;
  }
}
