import { createWebHistory, createRouter } from "vue-router";
import Store from "@/store/index";
import MainLayout from "@/views/main-layout.vue";

import Login from "@/views/login.vue";
import MemberManagement from "@/views/MemberManagement/member-list.vue";
import MemberManagementCreate from "@/views/MemberManagement/member-form.vue";
import NewsManagement from "@/views/NewsManagemnt/news-list.vue";
import NewsManagementCreate from "@/views/NewsManagemnt/news-form.vue";
import ProductManagement from "@/views/ProductManagement/product-list.vue";
import ProductManagementCreate from "@/views/ProductManagement/product-form.vue";

import QuotationRecord from "@/views/QuotationRecord/quotation-list.vue";
import PaymentMethod from "@/views/PaymentMethod/payment-list.vue";
import PaymentMethodCreate from "@/views/PaymentMethod/payment-form.vue";

import ShippingMethod from "@/views/ShippingMethod/shipping-list.vue";
import ShippingMethodCreate from "@/views/ShippingMethod/shipping-form.vue";

// import TreatmentManagement from "@/views/TreatmentManagement/treatmentmanagement-list.vue";
// import TreatmentManagementCreate from "@/views/TreatmentManagement/treatmentmanagement-form.vue";
// import BookingTime from "@/views/BookingTime/bookingtime-list.vue";
// import BookingTimeCreate from "@/views/BookingTime/bookingtime-form.vue";
// import OnlineBookingRecord from "@/views/OnlineBookingRecord/onlinebooking-list.vue";
// import OrderRecord from "@/views/OrderRecord/order-list.vue";

import AboutUs from "@/views/CMS/aboutus.vue";
import ContactUs from "@/views/CMS/contactus.vue";
import PrivacyPolicy from "@/views/CMS/privacy-policy.vue";
import TermsAndConditions from "@/views/CMS/terms-condition.vue";
import ProfileSetting from "@/views/ProfileSettings/profile.vue";


const routes = [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        title: "Login",
      },
    },

  {
    component: MainLayout,
    children: [
      {
        path: "/member-management",
        name: "MemberManagement",
        component: MemberManagement,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member management",
        },
      },
      {
        path: "/member-management/create",
        name: "MemberManagementCreate",
        component: MemberManagementCreate,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management > Add",
        },
      },
      {
        path: "/member-management/edit/:id",
        name: "MemberManagementEdit",
        component: MemberManagementCreate,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management > Edit",
        },
      },
      {
        path: "/news-management",
        name: "NewsManagement",
        component: NewsManagement,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management",
        },
      },
      {
        path: "/news-management/create",
        name: "NewsManagementCreate",
        component: NewsManagementCreate,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management > Add",
        },
      },
      {
        path: "/news-management/edit/:id",
        name: "NewsManagementEdit",
        component: NewsManagementCreate,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management > Edit",
        },
      },
      {
        path: "/product-management",
        name: "ProductManagement",
        component: ProductManagement,
        meta: {
          requiresAuth: true,
          module: "ProductManagement",
          title: "Product Management",
        },
      },
      {
        path: "/product-management/create",
        name: "ProductManagementCreate",
        component: ProductManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ProductManagement",
          title: "Product Management > Add",
        },
      },
      {
        path: "/product-management/edit/:id",
        name: "ProductManagementEdit",
        component: ProductManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ProductManagement",
          title: "Product Management > Edit",
        },
      },
      {
        path: "/quotation",
        name: "QuotationRecord",
        component: QuotationRecord,
        meta: {
          requiresAuth: true,
          module: "QuotationRecord",
          title: "Quotation Record",
        },
      },
      {
        path: "/payment-method",
        name: "PaymentMethod",
        component: PaymentMethod,
        meta: {
          requiresAuth: true,
          module: "PaymentMethod",
          title: "Payment Method",
        },
      },
      {
        path: "/payment-method/create",
        name: "PaymentMethodCreate",
        component: PaymentMethodCreate,
        meta: {
          requiresAuth: true,
          module: "PaymentMethod",
          title: "Payment Method > Add",
        },
      },
      {
        path: "/payment-method/edit/:id",
        name: "PaymentMethodEdit",
        component: PaymentMethodCreate,
        meta: {
          requiresAuth: true,
          module: "PaymentMethod",
          title: "Payment Method > Edit",
        },
      },

      {
        path: "/shipping-method",
        name: "ShippingMethod",
        component: ShippingMethod,
        meta: {
          requiresAuth: true,
          module: "ShippingMethod",
          title: "Shipping Method",
        },
      },
      {
        path: "/shipping-method/create",
        name: "ShippingMethodCreate",
        component: ShippingMethodCreate,
        meta: {
          requiresAuth: true,
          module: "ShippingMethod",
          title: "Shipping Method > Add",
        },
      },
      {
        path: "/shipping-method/edit/:id",
        name: "ShippingMethodEdit",
        component: ShippingMethodCreate,
        meta: {
          requiresAuth: true,
          module: "ShippingMethod",
          title: "Shipping Method > Edit",
        },
      },
      // {
      //   path: "/bookigtimeslot",
      //   name: "BookingTime",
      //   component: BookingTime,
      //   meta: {
      //     requiresAuth: true,
      //     module: "BookingTime",
      //     title: "Bookig Timeslot",
      //   },
      // },
      // {
      //   path: "/bookigtimeslot/create",
      //   name: "BookingTimeCreate",
      //   component: BookingTimeCreate,
      //   meta: {
      //     requiresAuth: true,
      //     module: "BookingTime",
      //     title: "Bookig Timeslot > Add",
      //   },
      // },
      // {
      //   path: "/bookigtimeslot/edit/:id",
      //   name: "BookingTimeEdit",
      //   component: BookingTimeCreate,
      //   meta: {
      //     requiresAuth: true,
      //     module: "BookingTime",
      //     title: "Bookig Timeslot > Edit",
      //   },
      // },
      // {
      //   path: "/online-booking-management",
      //   name: "OnlineBookingRecord",
      //   component: OnlineBookingRecord,
      //   meta: {
      //     requiresAuth: true,
      //     module: "OnlineBookingRecord",
      //     title: "OnlineBookingRecord",
      //   },
      // },
      // // {
      // //   path: "/online-booking-management/create",
      // //   name: "OnlineBookingRecordCreate",
      // //   component: OnlineBookingRecordCreate,
      // //   meta: {
      // //     requiresAuth: true,
      // //     module: "OnlineBookingRecord",
      // //     title: "OnlineBookingRecord > Add",
      // //   },
      // // },
      // {
      //   path: "/order-record",
      //   name: "OrderRecord",
      //   component: OrderRecord,
      //   meta: {
      //     requiresAuth: true,
      //     module: "OrderRecord",
      //     title: "Order Record",
      //   },
      // },
      // // {
      // //   path: "/order-record/create",
      // //   name: "OrderRecordCreate",
      // //   component: OrderRecordCreate,
      // //   meta: {
      // //     requiresAuth: true,
      // //     module: "OrderRecord",
      // //     title: "Order Record > Add",
      // //   },
      // // },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        meta: {
          requiresAuth: true,
          module: "AboutUs",
          title: "About Us",
        },
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
        meta: {
          requiresAuth: true,
          module: "ContactUs",
          title: "Contact Us",
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
          requiresAuth: true,
          module: "PrivacyPolicy",
          title: "Privacy Policy",
        },
      },
      {
        path: "/terms-condition",
        name: "TermsAndConditions",
        component: TermsAndConditions,
        meta: {
          requiresAuth: true,
          module: "TermsAndConditions",
          title: "Terms And Conditions",
        },
      },

      {
        path: "/profile-setting",
        name: "ProfileSetting",
        component: ProfileSetting,
        meta: {
          requiresAuth: true,
          module: "ProfileSetting",
          title: "Profile Setting",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });
  router.beforeEach((to, from, next) => {
    const title = "Hok Shing Group Food";
    if (
      Store.state.timeout == null ||
      (Store.state.timeout != null && Store.state.timeout > new Date())
    ) {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Store.state.hasLoggedIn) {
          Store.commit("setUserTimeout");
          Store.commit("setActiveRoute", to.meta.module);
          window.document.title =
            to.meta && to.meta.title ? title + " - " + to.meta.title : title;
          next();
          return;
        }
        Store.dispatch("logout");
        next("/login");
        return;
      } else if (to.name === undefined) {
        if (Store.state.hasLoggedIn) {
          Store.commit("setActiveRoute", to.meta.module);
          window.document.title =
            to.meta && to.meta.title ? title + " - " + to.meta.title : title;
          next();
          return;
        }
        Store.dispatch("logout");
        next("/login");
        return;
      } else {
        Store.commit("setUserTimeout");
        if (to.name == "Login" && Store.state.hasLoggedIn) {
          Store.commit("setActiveRoute", "MemberManagement");
          next("/member-management");
          return;
        } else {
          window.document.title =
            to.meta && to.meta.title ? title + " - " + to.meta.title : title;
          next();
          return;
        }
      }
    } else {
      Store.dispatch("logout");
      next("/login");
      return;
    }
  });
export default router;
