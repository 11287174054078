// import DateFormat from "@/libs/DateFormat";
export default class Product {
  constructor(data = {}) {
    return {
      id: data.id || "",

      name: data.name || "",
      description: data.description || "",
      image: data.image || "",
      // price: data.price || "",
      //   createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
